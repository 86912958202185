import axios from "axios";
const turntable = {
    videoinfo(){
        return axios.get('/api/ppy/usercenter/newbie/video/info')
    },
    record(){
        return axios.get('/api/ppy/usercenter/newbie/video/record')
    },
    videodraw(){
        return axios.get('/api/ppy/usercenter/newbie/video/draw')
    },
    drawQuery(data){
        return axios.get('/api/ppy/usercenter/newbie/video/drawQuery',{
            params:{
                orderNum:data
            }
        })
    },
    marquee(){
        return axios.get('/api/ppy/usercenter/newbie/video/marquee')
    },
}///api/newbie/video/marquee
export default turntable;