<template>
  <div class="turntable">
    <div class="horselamps">
        <div class="horselamp"  :style="`width:${redswidth*2.2}px;`">
        <div  :style="`margin-left:${-gotime}px;`"  >
            <div v-for="(item,index) in horselamp" :key="index" ref="reds">
            <img :src="item.headImg?item.headImg:'https://ppyos.xijiuyou.com/imgs/invite/default_avatar.png'" alt="">
            <span>{{item.nickName}}刚刚抽中了<span>{{item.note}}</span>元余额</span>
            </div>
        </div>
        <div>
            <div v-for="(item,index) in horselamp" :key="index" ref="reds">
            <img :src="item.headImg?item.headImg:'https://ppyos.xijiuyou.com/imgs/invite/default_avatar.png'" alt="">
            <span>{{item.nickName}}刚刚抽中了<span>{{item.note}}</span>元余额</span>
            </div>
        </div>
        </div>
    </div>
    <img
      class="turntable-title"
      src="https://xi-jiu-you.oss-cn-hangzhou.aliyuncs.com/bubblefish/2308/video-draw-banner.png"
      alt=""
    />
    <div class="turntable-time">
      <span>{{ tiantime(countdown) }}</span>{{ tiantime(countdown)==0?'':'天' }}<span>{{ tiantimes(countdown) }}</span>小时后活动结束
    </div>
    <div class="turntable-content">
        <div
          class="luckdraw-content"
          :style="'transform:rotate(' + rotate + 'deg);'+rotatecss"
        >
          <div v-for="(item, index) in turntablemoney" :key="index" :style="prizeStyle(index)">
            <p>{{ item.title }}</p>
            <img
              :src="item.icon"
              alt=""
            />
          </div>
        </div>
        <img
          class="luckdraw-btn"
          src="https://xi-jiu-you.oss-cn-hangzhou.aliyuncs.com/bubblefish/2308/draw-img.png"
          alt=""
          @click="playAd()"
        />
        <div @click="playAd()" class="turntable-content-btn" :style="remainTimes === 0?'background: url(https://xi-jiu-you.oss-cn-hangzhou.aliyuncs.com/bubblefish/2308/btn-bg-hui.png) no-repeat;background-size: 100%;': ''">
            <p v-if="remainTimes >0"><img src="https://xi-jiu-you.oss-cn-hangzhou.aliyuncs.com/bubblefish/2308/play-icon.png" alt=""/>看视频抽奖</p>
            <p v-else>请明天再来</p>
            <p>今日还剩 {{ remainTimes }} 次</p>
        </div>
    </div>
    <div class="jiangl">
        <div class="jiangl-title"><img src="https://xi-jiu-you.oss-cn-hangzhou.aliyuncs.com/bubblefish/2308/start-icon.png" alt="">我的奖励<img src="https://xi-jiu-you.oss-cn-hangzhou.aliyuncs.com/bubblefish/2308/start-icon.png" alt=""></div>
        <div class="jiangl-list" v-if="list.length>0">
            <p v-for="(item,index) in list" :key="index" @click="golinks(item.link)">
                <span>{{item.title}}</span>
                <span>{{time(item.ctime)}}</span>
            </p>
        </div>
        <div class="over-auto" v-else>
            <img src="https://xi-jiu-you.oss-cn-hangzhou.aliyuncs.com/bubblefish/2308/draw-box.png" alt="">
            <p>还未获得奖励，快去抽奖</p>
        </div>
        <div class="bottomimg" v-if="scrollTop" @click="scrollTopbtn">
            <img src="https://xi-jiu-you.oss-cn-hangzhou.aliyuncs.com/bubblefish/2308/goto-top-icon.png" alt="">
        </div>
        <!-- <img class="bottomimg" src="https://xi-jiu-you.oss-cn-hangzhou.aliyuncs.com/bubblefish/2308/sot-line-pink.png" alt=""> -->
    </div>
    <xwpopup :isshowpopup="succnewtime" @close="closesuccnewtime()" :hiddenClose="false">
        <div class="toatalert">
            <img src="https://xi-jiu-you.oss-cn-hangzhou.aliyuncs.com/bubblefish/2308/success-tips.png" alt="" />
            <img :src="tostalert.icon" alt="" />
            <p>{{tostalert.title}}</p>
            <p>{{tostalert.note}}~</p>
            <div v-if="tostalert.type === 0" @click="goout()">
                <img src="https://xi-jiu-you.oss-cn-hangzhou.aliyuncs.com/bubblefish/2308/draw-btn-1.png" alt="">
            </div>
            <div class="sdiv" v-else>
                <img src="https://xi-jiu-you.oss-cn-hangzhou.aliyuncs.com/bubblefish/2308/draw-btn-2.png" @click="playAd" alt="">
                <img @click="goout()" :src="tostalert.type === 1?'https://xi-jiu-you.oss-cn-hangzhou.aliyuncs.com/bubblefish/2308/goto-view.png':'https://xi-jiu-you.oss-cn-hangzhou.aliyuncs.com/bubblefish/2308/go-play-btn-img.png'" alt="">
            </div>
        </div>
    </xwpopup>
  </div>
</template>
<script>
import dayjs from "dayjs";
import { Toast } from "vant";
import turntable from "../api/turntable";
import xwpopup from "../components/Popupbox.vue";
export default {
    components: {
        xwpopup,
    },
  data() {
    return {
      rotate: 0,
      rotatecss:"",
      num: 100,
      turntablemoney: [],
      list:[],
      countdown:0,
      remainTimes:1,
      succnewtime:false,
      tostalert:{},
      btnsucc:true,
      scrollTop:false,
      redswidth:0,
      gotime:0,
      horselamp:[
      ],
    };
  },
  computed:{
    rotateAngle(){
        const _degree = 360 / this.turntablemoney.length
          return _degree;
    },
     prizeStyle (){
          const _degree =this.rotateAngle
          return (i) => {
            return `
              width: ${2 * 125 * Math.sin(_degree / 2 * Math.PI / 180)}px;
              height: 135px;
              transform: rotate(${(_degree * i + _degree / 2)-19}deg);
              transform-origin: 50% 100%;
              left:105px;
              top:-5px;
            `
          }
        }
  },
  destroyed() { //离开这个界面之后，删除滚动事件，不然容易除bug
    window.removeEventListener('scroll', this.handleScroll)
  },
  mounted() {
    this.videoinfo();
    this.record();
    this.marquee();
    try {
        window.android.trackEvent(`LuckyWheel_main_open`,"");
    } catch (error) {
        console.log(error)
    }
    
    window.addEventListener('scroll', this.handleScroll, true);
    window.dk ={
        playAdAppCallBack : (responseData)=>{
            var responseJson = JSON.parse(responseData);
            if (responseJson.status >10000 || responseJson.status < 0 ){
                if(!this.succnewtime){
                    this.rotateclick();
                }
            }
            
        },
        checkAdAppCallBack:(responseData)=>{
            var responseJson = JSON.parse(responseData);
            if(responseJson.hasAd && responseJson.canClick){
                try {
                    window.android.playAd(
                        JSON.stringify({'type': 0, 'platform': 0})
                    );
                } catch (error) {
                    console.log(error);
                    Toast("打开视频失败，请稍后再试");
                }
            }else{
                Toast("暂无广告可以播放");
            }
        }
    }
  },
  methods: {
    marquee(){
        turntable.marquee().then(res=>{
            console.log(res)
        this.horselamp = res.list;
        this.gohorse();
      })
    },
    gohorse(){
      const that = this;
      that.$nextTick(()=>{
        that.$refs.reds.map(res=>{
          that.redswidth = that.redswidth+res.clientWidth;
        })
        that.times = setInterval(()=>{
          if(that.gotime>= that.redswidth){
            that.gotime = 0
          }else{
            that.gotime++
          }
        },10)
      })
    },
    scrollTopbtn(){
        document.documentElement.scrollTop=0;
        document.body.scrollTop = 0;
    },
    golinks(res){
        window.location.href = res;
    },
    handleScroll() {
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
       if(scrollTop >200){
        this.scrollTop = true;
       }else{
        this.scrollTop = false;
       }
    },
    goout(){
            window.location.href = this.tostalert.link;
            this.succnewtime = false;
        
    },
    closesuccnewtime(){
        this.succnewtime = !this.succnewtime;
    },
    playAd(){
        if(this.remainTimes <= 0){
            Toast("您当天次数已用完，请明天再来");
            return false;
        }
        this.succnewtime = false;
        try {
            window.android.checkAd(
                JSON.stringify({'type': 0, 'platform': 0})
            );
        } catch (error) {
            console.log(error);
            Toast("打开视频失败，请稍后再试");
        }
    },
    tiantime(res){
        if(res/60/60/24 >=1){
            return Math.floor(res/60/60/24);
        }else{
            return "";
        }
    },
    tiantimes(res){
        if(res/60/60/24 >=1){
            return Math.floor((res % (3600 * 24)) / 3600);
        }else{
            return Math.floor(res/60/60);
        }
    },
    time(item) {
        return dayjs(item).format("YYYY-MM-DD HH:mm:ss");
    },
    videoinfo(){
        turntable.videoinfo().then(res=>{
            this.countdown = res.data.countdown;
            this.remainTimes = res.data.remainTimes;
            this.turntablemoney = res.data.items
        })
    },
    record(){
        turntable.record().then(res=>{
            this.list =res.list
        })
    },
    rotateclick() {
      const that = this;
      if(!this.btnsucc){
        return false;
      }
      if(that.rotate%360 != 0){
        that.rotate =  that.rotate +(360- that.rotate%360);
        this.rotatecss = "transition:transform 1s ease-out;";
      }
      that.rotate
      this.btnsucc = false;
      const rotate = setInterval(() => {
        if (that.num == 0) {
            turntable.videodraw().then((res) => {
                const times = setTimeout(()=>{
                    clearTimeout(times);
                turntable.drawQuery(res.data).then(_res=>{
                    console.log(_res)
                    that.turntablemoney.map((res_, index) => {
                        if (res_.iid == _res.data.iid) {
                            clearInterval(rotate);
                            
                            this.num = 100;
                            if (that.rotate % 360 >= (index) * 36) {
                                that.rotate = that.rotate + 360  - (index) * 36 + 360  +(360- that.rotate%360);
                                this.rotatecss = "transition:transform 2s ease-out;";
                            } else if (that.rotate % 360 < (index ) * 36) {
                                that.rotate = that.rotate - ((index) * 36 ) + 360 +(360- that.rotate%360);
                                this.rotatecss = "transition:transform 2s ease-out;";
                            }
                            const time = setTimeout(()=>{
                                this.succnewtime = true;
                                this.tostalert = _res.data;
                                this.btnsucc = true;
                                this.videoinfo();
                                this.record();
                                clearTimeout(time)
                            },2500)
                        }
                    });
                }).catch(()=>{
                    clearInterval(rotate);
                    this.videoinfo();
                    this.record();
                    this.num = 100;
                })
                },1000)
                return false;
            })
        }
        this.rotate = this.rotate + 5;
        this.num--;
      }, 10);
    },
  },
};
</script>
<style lang="less" scoped>
.turntable {
  width: 100%;
  min-height: 100vh;
  background: url("https://xi-jiu-you.oss-cn-hangzhou.aliyuncs.com/bubblefish/2308/big-bg.png")
    no-repeat;
  background-size: 100%;
  background-color: RGBA(255, 177, 206, 1);
  padding-top: 10px;
  text-align: center;
  padding-bottom: 30px;
  overflow: hidden;
  .turntable-title {
    width: calc(100% - 84px);
  }
  .turntable-time {
    width: 193px;
    height: 26px;
    background: rgba(255, 224, 146, 0.6);
    border-radius: 13px;
    border: 1px solid #ffcb73;
    backdrop-filter: blur(10px);
    text-align: center;
    line-height: 26px;
    margin: 0 auto 28px;
    margin-top: 13px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #444444;
    span {
      font-size: 16px;
      font-family: DINOffcPro-Bold, DINOffcPro;
      font-weight: bold;
      color: #ff4b7f;
    }
  }
  .turntable-content {
    width: 326px;
    height: 386px;
    margin: 0px auto 0;
    background: url("https://xi-jiu-you.oss-cn-hangzhou.aliyuncs.com/bubblefish/2308/box-bg.png")
      no-repeat;
    background-size: 100%;
    position: relative;
  }
}
.luckdraw-content {
  width: 286px;
  height: 286px;
  position: relative;
  background: url("https://xi-jiu-you.oss-cn-hangzhou.aliyuncs.com/bubblefish/2308/chain-bg.png")
    no-repeat;
  background-size: 100% 100%;
  left: 0;
  top: 20px;
  right: 0;
  bottom: 0;
  margin: auto;
}
.luckdraw-btn {
  width: 132px;
  height: 158px;
  position: absolute;
  left: 0;
  top: -60px;
  right: 0;
  bottom: 0;
  margin: auto;
}
.luckdraw-content div {
    font-size: 20px;
    font-family: DINOffcPro-Bold, DINOffcPro;
    font-weight: bold;
    color: #FF1968;
  text-align: center;
  margin-top: 15px;
  position: absolute;
  width: 100%;
  p{
    padding-right: 3px;
  }
}
// .luckdraw-content div:nth-child(2) {
//   right: -60px;
//   top: 20px;
//   transform: rotate(40deg);
// }
// .luckdraw-content div:nth-child(3) {
//   right: -90px;
//   top: 70px;
//   transform: rotate(65deg);
// }
// .luckdraw-content div:nth-child(4) {
//   right: -90px;
//   top: 130px;
//   transform: rotate(110deg);
// }
// .luckdraw-content div:nth-child(5) {
//   right: -55px;
//   top: 180px;
//   transform: rotate(150deg);
// }
// .luckdraw-content div:nth-child(6) {
//   right: 0px;
//   top: 195px;
//   transform: rotate(180deg);
// }
// .luckdraw-content div:nth-child(7) {
//   right: 60px;
//   top: 180px;
//   transform: rotate(215deg);
// }
// .luckdraw-content div:nth-child(8) {
//   right: 100px;
//   top: 130px;
//   transform: rotate(250deg);
// }
// .luckdraw-content div:nth-child(9) {
//     right: 95px;
//   top: 70px;
//   transform: rotate(285deg);
// }
.luckdraw-content div img {
  width: 34px;
  height: 30px;
}
.turntable-content-btn{
    position: absolute;
    width: 204px;
    height: 61px;
    background: url("https://xi-jiu-you.oss-cn-hangzhou.aliyuncs.com/bubblefish/2308/btn-bg.png") no-repeat;
    background-size: 100%;
    z-index: 1;
    margin: 0 auto;
    padding-top: 6px;
    margin-left: calc(50% - 102px);
    bottom: -23px;
    p{
        font-size: 12px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
    }
    p:nth-child(1){
        display: flex;
        font-size: 20px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
        justify-content: center;
        align-items: center;
        img{
            width: 16px;
            height: 16px;
            margin-right: 4px;
        }
    }
}
.jiangl{
    width: calc(100% - 28px);
    margin: 38px auto 0;
    background: rgba(255, 255, 255, 0.39);
    border-radius: 15px;
    padding-top: 12px;
    padding-bottom: 10px;
    position: relative;
    .bottomimg{
        position: fixed;
        right: 20px;
        bottom: 40px;
        width: 44px;
        height: 44px;
        background: rgba(0, 0, 0, 0.6);
        border-radius: 50%;
        img{
            width: 18px;
            height: 26px;
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            margin: auto;
        }
    }
    .jiangl-title{
        font-size: 16px;
        font-family: B2SC-Black, B2SC;
        font-weight: 900;
        color: #5F1F2F;
        margin-bottom: 18px;
        img{
            width: 10px;
            height: 10px;
            margin-right: 9px;
        }
        img:nth-child(2){
            margin: 0;
            margin-left: 9px;
        }
    }
    .jiangl-list{
        width: 100%;
        padding: 0 16px;
        // max-height: 135px;
        // overflow: auto;
        p{
            display: flex;
            justify-content: space-between;
            margin-bottom: 18px;
            span{
                color: #5F1F2F;
            }
            span:nth-child(2){
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #5F1F2F;
            }
        }
    }
}
.over-auto{
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #5F1F2F;
    margin-bottom: 25px;
    img{
        width: 73px;
        margin-bottom: 11px;
        margin-top: 10px;
    }
}
.toatalert{
    width: calc(100% - 100px);
    background: #FFFFFF;
    border-radius: 15px;
    margin-top: 100px;
    text-align: center;
    padding-bottom: 26px;
    img:nth-child(1){
        width: 100%;
        margin-top: -23px;
    }
    img:nth-child(2){
        width: 70px;
        margin-top: -18px;
    }
    p:nth-child(3){
        font-size: 18px;
        font-family: DINOffcPro-Bold, DINOffcPro;
        font-weight: bold;
        color: #FF1968;
        margin-bottom: 10px;
    }
    p:nth-child(4){
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
    }
    div:nth-child(5){
        margin-top: 18px;
        img{
            margin: 0;
            width: calc(100% - 100px);
            height: 48px;
        }
    }
    .sdiv:nth-child(5){
        margin-top: 18px;
        display: flex;
        padding:0 20px;
        justify-content: center;
        img{
            margin: 0;
            width: 108px;
            height: 48px;
        }
        img:nth-child(1){
            margin-right: 15px;
        }
    }
}
.horselamp{
  position: absolute;
  width: 100vw;
  left: 0;
  top: 0;
  z-index: 1000;
  display: flex;
  overflow: hidden;
}
.horselamp div div img{
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 5px;
  float: left;
  margin: 4px;
}
.horselamp div div {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  display: inline-block;
  height: 32px;
  background: rgba(255, 0, 97, 0.30);
  border-radius: 16px;
  box-sizing: border-box;
  margin-right:80px;
}
.horselamp div{
  display: block;
}
.horselamp div div span{
  line-height: 32px;
  height: 32px;
  overflow: hidden;
  width: calc(100% - 32px);
  white-space: nowrap;
  text-overflow: ellipsis;
  float: left;
  display: flex;
  align-items: center;
  padding-right: 4px;
  span{
    color: rgba(255, 57, 113, 1);
    padding:0;
    margin: 0;
  }
}
.horselamps{
    width: 100vw;
    height: 35px;
    overflow: hidden;
    position: relative;
}
</style>